import { YourIR } from 'yourir-next';

export default function AsxSubscribeForm()
{
    return (
        <YourIR>
            <form data-yourir="mailList">
                <div className="sm:flex">
                    <input
                        type="text"
                        className="grow w-full bg-white py-20 px-40 border-0 rounded-lg sm:rounded-tr-none sm:rounded-br-none mb-20 sm:mb-0 focus:ring-2 focus:border-bb focus:ring-bb"
                        placeholder="Your Email Address"
                        data-yourir="emailAddress"
                        title="Enter your email to subscribe to ASX announcements"
                    />
                    <input
                        type="button"
                        data-yourir="subscribe"
                        className="block bg-cc text-white min-w-[220px] px-40 py-20 mx-auto cursor-pointer rounded-full sm:rounded-tr-lg sm:rounded-br-lg sm:rounded-tl-none sm:rounded-bl-none hover:bg-cc/85"
                        value="Subscribe"
                    />
                </div>
                <div
                    className="rounded mt-40 p-20 sm:max-w-[50%] mx-auto"
                    data-yourir="result"
                ></div>
            </form>
        </YourIR>
    );
}