import BtnDefault from '../btn/btn-default';
import BtnHeadNav from '../btn/btn-headnav';
import Image from 'next/image';
import Link from 'next/link';
import React, { Component } from "react";
import MobileMenu from '../mobile-menu';
import Caption from '../text/caption';
import AfsLogo from '../../public/img/logos/afs_01.svg';
import GcfLogo from '../../public/img/logos/gcf_01.svg';
import Money3Logo from '../../public/img/logos/money3_02.svg';
import SolvarLogo from '../../public/img/logos/solvar_01.svg';

class Header extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            active: false,
        }

        this.listenToScroll = this.listenToScroll.bind(this);
        this.checkActiveState = this.checkActiveState.bind(this);
    }

    componentDidMount()
    {
        this.checkActiveState();
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount()
    {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll()
    {
        this.checkActiveState();
    }

    checkActiveState()
    {
        const scrollTop = document.documentElement.scrollTop;

        this.setState({
            active: ( scrollTop > ( this.props.scrollOffset ?? 100 ) )
        });
    }

    render()
    {
        const { active } = this.state;

        return (
            <header>
                <div className="fixed top-0 right-0 left-0 z-header pointer-events-none shadow">
                    <div className={ "transition-colors pointer-events-auto relative bg-white" }>
                        <div className={"transition-spacing container flex items-center " + " " + ( active ? "py-20" : "py-30" )}>
                            <Link href="/" className="relative h-[42px] xl:h-[56px] w-[188px] xl:w-[251px]" passHref>
                                <Image src={ SolvarLogo } layout="responsive" alt="solvar_01" height="56" width="251.01" />
                            </Link>
                            <nav className="hidden lg:flex grow items-center gap-20 lg:gap-40 xl:gap-60 justify-end pr-20 lg:pr-40 xl:pr-60">
                                <BtnHeadNav
                                    items={(
                                        <div className="flex whitespace-nowrap p-20">
                                            <div>
                                                <Caption className="block p-20">About Us</Caption>
                                                <Link href="/about-us/company-overview" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Company overview
                                                </Link>
                                                <Link href="/about-us/history" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    History
                                                </Link>
                                                <Link href="/about-us/board-of-directors" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Board of directors
                                                </Link>
                                                <Link href="/about-us/management-team" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Management team
                                                </Link>
                                                <Link href="/about-us/corporate-governance" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Corporate governance
                                                </Link>
                                                <Link href="/about-us/philanthropy" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Philanthropy
                                                </Link>
                                                <Link href="/about-us/sustainability" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Sustainability
                                                </Link>
                                            </div>
                                            <div>
                                                <Caption className="block p-20">Investors</Caption>
                                                <Link href="/investors/shareholder-information" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Shareholder information
                                                </Link>
                                                <Link href="/investors/company-announcements" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Company announcements
                                                </Link>
                                                <Link href="/investors/annual-reports" className="block text-bb no-underline p-20 hover:bg-grey/50">
                                                    Annual reports
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                    href="/about-us/company-overview"
                                >Investors</BtnHeadNav>
                                <BtnHeadNav
                                    items={(
                                        <div className="flex flex-col gap-10 p-10">
                                            <Caption className="block text-sm pt-20 text-center">Our Brands</Caption>
                                            <Link href="https://www.afs.com.au/" className="relative block p-20 rounded bg-white border-2 border-grey w-[280px] hover:bg-grey/50" passHref>
                                                <Image src={ AfsLogo } alt="Automotive Financal Services" />
                                            </Link>
                                            <Link href="https://www.gocar.co.nz/" className="relative block p-20 rounded bg-white border-2 border-grey w-[280px] hover:bg-grey/50" passHref>
                                                <Image src={ GcfLogo } alt="Go Car Finance" />
                                            </Link>
                                            <Link href="https://www.money3.com.au/" className="relative block p-20 rounded bg-white border-2 border-grey w-[280px] hover:bg-grey/50" passHref>
                                                <Image src={ Money3Logo } alt="Money3" />
                                            </Link>
                                        </div>
                                    )}
                                    href="/about-us/company-overview"
                                >Our Brands</BtnHeadNav>
                                <BtnHeadNav href="/contact/contact-us">Contact Us</BtnHeadNav>
                            </nav>
                            <nav className="hidden lg:flex items-center">
                                <BtnDefault size="large" href="/investors/company-announcements">Company Announcements</BtnDefault>
                            </nav>
                            <div className="flex lg:hidden grow justify-end">
                                <MobileMenu active={ active }>
                                    <Caption className="block p-20">About Us</Caption>
                                    <Link href="/about-us/company-overview" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Company overview
                                    </Link>
                                    <Link href="/about-us/history" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        History
                                    </Link>
                                    <Link href="/about-us/board-of-directors" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Board of directors
                                    </Link>
                                    <Link href="/about-us/management-team" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Management team
                                    </Link>
                                    <Link href="/about-us/corporate-governance" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Corporate governance
                                    </Link>
                                    <Link href="/about-us/philanthropy" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Philanthropy
                                    </Link>
                                    <Link href="/about-us/sustainability" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Sustainability
                                    </Link>
                                    <Caption className="block text-sm p-20">Investors</Caption>
                                    <Link href="/investors/shareholder-information" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Shareholder information
                                    </Link>
                                    <Link href="/investors/company-announcements" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Company announcements
                                    </Link>
                                    <Link href="/investors/annual-reports" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Annual reports
                                    </Link>
                                    <Caption className="block text-sm p-20">Contact</Caption>
                                    <Link href="/contact/contact-us" className="block text-bb no-underline p-20 hover:bg-white/50 capitalize">
                                        Contact Us
                                    </Link>
                                    <Caption className="block text-sm p-20">Our Brands</Caption>
                                    <Link href="https://www.afs.com.au/" className="relative flex justify-center items-center p-20 rounded bg-white border-2 border-grey-2 max-h-[100px] hover:opacity-75 mb-10" passHref>
                                        <Image src={ AfsLogo } alt="Automotive Financal Services" className="max-h-[50px]" />
                                    </Link>
                                    <Link href="https://www.gocar.co.nz/" className="relative flex justify-center items-center p-20 rounded bg-white border-2 border-grey-2 max-h-[100px] hover:opacity-75 mb-10" passHref>
                                        <Image src={ GcfLogo } alt="Go Car Finance" className="max-h-[50px]" />
                                    </Link>
                                    <Link href="https://www.money3.com.au/" className="relative flex justify-center items-center p-20 rounded bg-white border-2 border-grey-2 max-h-[100px] hover:opacity-75 mb-10" passHref>
                                        <Image src={ Money3Logo } alt="Money3" className="max-h-[50px]" />
                                    </Link>
                                </MobileMenu>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
