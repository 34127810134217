import Link from 'next/link';

export default function BtnDefault(props)
{
    const { className, children, href, type, onClick, color, size, disabled, target } = props;

    let typeClassName, sizeClassName;

    if (disabled) {
        // bg-[#EEEEF1] is slightly darker then grey so that it is still visible on grey backgrounds.
        typeClassName = "inline-block bg-[#EEEEF1] text-grey-2 font-bold text-center no-underline rounded rounded-full";

    } else if (type == 'outline') {
        switch(color) {
            case 'cc':
                typeClassName = "inline-block text-cc font-bold text-center no-underline border-3 border-color-cc rounded rounded-full hover:text-cc group-hover:text-cc hover:bg-white group-hover:bg-white";
                break;
            case 'white':
                typeClassName = "inline-block text-white font-bold text-center no-underline border-3 border-color-white rounded rounded-full hover:border-white hover:text-bb group-hover:text-bb hover:bg-white group-hover:bg-white";
                break;
            case 'bb':
            default:
                typeClassName = "inline-block text-bb font-bold text-center no-underline border-3 border-color-bb rounded rounded-full hover:text-bb group-hover:text-bb hover:bg-white group-hover:bg-white";
                break;
        }
    } else {
        switch(color) {
            case 'cc':
                typeClassName = "inline-block bg-cc text-white font-bold text-center no-underline rounded rounded-full hover:bg-cc/80 group-hover:bg-cc/80";
                break;
            case 'white':
                typeClassName = "inline-block bg-white text-bb font-bold text-center no-underline rounded rounded-full hover:text-bb/80 group-hover:text-bb/80";
                break;
            case 'bb':
            default:
                typeClassName = "inline-block bg-bb text-white font-bold text-center no-underline rounded rounded-full hover:bg-bb/80 group-hover:bg-bb/80";
                break;
        }
    }

    switch(size) {
        case 'large':
            sizeClassName ="py-20 min-w-[343px]";
            break;
        case 'small':
            sizeClassName = "py-[16px] px-40";
            break;
        default:
            sizeClassName = "py-20 min-w-[245px]";
            break;
    }


    typeClassName = typeClassName + " " + sizeClassName;

    if ( href && !disabled) {
        return (
            <Link href={ href } className={ typeClassName + (className ? " " + className : "") } target={ target }>
                { children }
            </Link>
        );
    }

    return (
        <div className={ typeClassName + (className ? " " + className : "") } onClick={ !disabled ? onClick : null }>{ children }</div>
    );
}
