import Header from '../blocks/header'
import Footer from '../blocks/footer'
import AsxSubscribe from '../blocks/asx-subscribe'
import ScrollTopArrow from '../scroll/scroll-top-arrow'

export default function MainLayout({ children }) {
  return (
    <>
        <div id="header">
            <Header />
        </div>
        <main className="bg-white">
            {children}
        </main>
        <ScrollTopArrow anchor="header"/>
        <AsxSubscribe />
        <Footer />
    </>
  )
}