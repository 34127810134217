import AsxSubscribeForm from '../asx/asx-subscribe-form';

export default function AsxSubscribe(props)
{
    const { className } = props;

    return (
        <section className={ "bg-grey text-center" + (className ? " " + className : "") }>
            <div className="container py-60">
                <h2 className="text-h1">ASX Announcements</h2>
                <p className="pb-40">Get the latest Solvar news alerts straight to your inbox. Subscribe now!</p>
                <AsxSubscribeForm />
            </div>
        </section>
    );
}