import Image from 'next/image'
import Link from 'next/link'
import InvestorsWhiteLogo from '../../public/img/logos/solvar_white_01.svg'
import AsxLogo from '../../public/img/logos/asx_white_sans_text_01.svg'
import TraceIcon from '../../public/img/trace/trace_01.png'
import Script from 'next/script';

export default function Footer() {
    return (
        <footer className="bg-bb text-white">
            <div className="container">
                <div className="py-60">
                    <Link href="/" passHref>
                        <Image src={ InvestorsWhiteLogo } alt="solvar_white_01" height="61" width="273" />
                    </Link>
                </div>
                <div className="border-b border-white opacity-50"></div>
                <div className="sm:grid sm:grid-cols-2 md:grid-cols-4 py-60 gap-60">
                    <ul>
                        <li className="pb-10 opacity-50 capitalize">About Us</li>
                        <li className="pb-10 font-bold">
                            <Link href="/about-us/company-overview" className="text-white no-underline hover:underline capitalize">
                                Company Overview
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/about-us/history" className="text-white no-underline hover:underline capitalize">
                                History
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/about-us/board-of-directors" className="text-white no-underline hover:underline capitalize">
                                Board of Directors
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/about-us/management-team" className="text-white no-underline hover:underline capitalize">
                                Management Team
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/about-us/corporate-governance" className="text-white no-underline hover:underline capitalize">
                                Corporate Governance
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/about-us/philanthropy" className="text-white no-underline hover:underline capitalize">
                                Philanthropy
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/about-us/sustainability" className="text-white no-underline hover:underline capitalize">
                                Sustainability
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="pb-10 opacity-50 capitalize">Investors</li>
                        <li className="pb-10 font-bold">
                            <Link href="/investors/shareholder-information" className="text-white no-underline hover:underline capitalize">
                                Shareholder Information
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/investors/company-announcements" className="text-white no-underline hover:underline capitalize">
                                Company Announcements
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/investors/annual-reports" className="text-white no-underline hover:underline capitalize">
                                Annual Reports
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="pb-10 opacity-50 capitalize">Contact</li>
                        <li className="pb-10 font-bold">
                            <Link href="/contact/contact-us" className="text-white no-underline hover:underline capitalize">
                                Contact Us
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="https://www.linkedin.com/company/solvarlimited" className="text-white no-underline hover:underline capitalize">
                                LinkedIn
                            </Link>
                        </li>
                        <li className="pb-10 opacity-50 capitalize">Legal</li>
                        <li className="pb-10 font-bold">
                            <Link href="/legal/privacy-policy" className="text-white no-underline hover:underline capitalize">
                                Privacy Policy
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/docs/solvar-modern-slavery-statement-2024.pdf" className="text-white no-underline hover:underline capitalize" target="_blank">
                                Modern Slavery Statement 2024
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="/legal/disclaimer" className="text-white no-underline hover:underline capitalize">
                                Disclaimer
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li className="pb-10 opacity-50 capitalize">Our Brands</li>
                        <li className="pb-10 font-bold">
                            <Link href="https://www.afs.com.au/" className="text-white no-underline hover:underline capitalize">
                                Automotive Financial Services
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="https://www.gocar.co.nz/" className="text-white no-underline hover:underline capitalize">
                                Go Car Finance
                            </Link>
                        </li>
                        <li className="pb-10 font-bold">
                            <Link href="https://www.money3.com.au/" className="text-white no-underline hover:underline capitalize">
                                Money3
                            </Link>
                        </li>
                        <li className="pt-20">
                            <trace-badge instance-id='2f3f1d95-0410-44f3-a755-10077af91f83' style={{ height: '100px', width: '218px', display: 'block' }}/>
                            <Script src="https://public.our-trace.com/scripts/trace-badge.js" />
                        </li>
                    </ul>
                </div>
                <div className="text-center">
                    <Image src={ AsxLogo } alt="asx_white_sans_text_01" height="80" width="50" />
                    <p className="pb-60">
                        Copyright © 2022 Solvar Limited / ABN 63 117 296 143.
                        <br />
                        Australian Credit Licence 389 782 / <Link href="tel:0390938255" className="text-white underline">03 9093 8255</Link>
                    </p>
                </div>
            </div>
        </footer>
    )
}
