import React, { Component } from "react";;
import { Menu } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";
import SolvarLogo from '../public/img/logos/solvar_01.svg';
import MenuClose from '../public/img/ui/menu_close_01.svg';
import MenuOpen from '../public/img/ui/menu_01.svg';

class MobileMenu extends Component {

    constructor(props)
    {
        super(props);
    }

    render()
    {
        const { children, active } = this.props;

        return (
            <Menu>
                <Menu.Button>
                    <div className="cursor-pointer">
                        <div className="block">
                            <Image src={ MenuOpen } alt="menu_01" height="26" width="33.8" />
                        </div>
                    </div>
                </Menu.Button>
                <Menu.Items>
                    <Menu.Item>
                        <div className="fixed left-0 right-0 top-0 bottom-0 bg-white flex flex-col">
                            <div className={ "transition-all container flex items-center" + ( active ? " py-20" : " py-30" ) }>
                                <Link href="/" className="relative h-[42px] xl:h-[56px] w-[188px] xl:w-[251px]" passHref>
                                    <Image src={ SolvarLogo } layout="responsive" alt="solvar_01" height="56" width="251.01" />
                                </Link>
                                <div className="grow"></div>
                                <div className="">
                                    <Menu.Button>
                                        <div className="cursor-pointer">
                                            <Image src={ MenuClose } alt="menu_01" height="26" width="33.8" />
                                        </div>
                                    </Menu.Button>
                                </div>
                            </div>
                            <div className="relative grow overflow-y-scroll bg-grey pt-30">
                                <div className="container pb-30">
                                    { children }
                                </div>
                            </div>
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Menu>
        );
    }
}

export default MobileMenu;