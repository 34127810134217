import React, { Component } from "react";

class ScrollDownArrow extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            active: false,
        }

        this.listenToScroll   = this.listenToScroll.bind(this);
        this.checkActiveState = this.checkActiveState.bind(this);
        this.scrollToAnchor   = this.scrollToAnchor.bind(this);
        this.handleClick      = this.handleClick.bind(this);
    }

    componentDidMount()
    {
        this.checkActiveState();
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount()
    {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll()
    {
        this.checkActiveState();
    }

    checkActiveState()
    {
        const scrollTop = document.documentElement.scrollTop;

        this.setState({
            active: ( scrollTop > ( this.props.scrollOffset ?? 500 ) )
        });
    }

    scrollToAnchor()
    {
        const { anchor } = this.props;

        if ( !anchor ) {
            return;
        }

        const element = document.getElementById(anchor);

        if ( !element ) {
            return;
        }

        // @see: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
        element.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    }

    handleClick()
    {
        this.scrollToAnchor();
    }

    render()
    {
        const { className, anchor } = this.props;
        const { active } = this.state;

        const defaultClassName = [
            "hidden",
            "fixed",
            "bg-cc",
            "w-[50px]",
            "h-[50px]",
            "bottom-60",
            "right-60",
            "p-[16px]",
            "rounded-full",
            "transition-all",
            "shadow-md",
            "z-scroll-arrow",
        ];

        defaultClassName.push(( anchor ? "cursor-pointer hover:bg-cc/80" : "" ));
        defaultClassName.push(( active ? "lg:block" : "" ));

        return (
            <div
                className={ defaultClassName.join(" ") + (className ? " " + className : "" )}
                onClick={ this.handleClick }
                title="Scroll To Top"
            >
                <span className="text-white"></span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.13 13.72">
                    <polyline points="10.6 6.1 5.57 1.06 0.53 6.1" style={{fill:"none", stroke:"#fff", strokeMiterlimit:"10", strokeWidth:"1.5px"}}/>
                    <line x1="5.57" y1="13.72" x2="5.57" y2="1.06" style={{fill:"none", stroke:"#fff", strokeMiterlimit:"10", strokeWidth:"1.5px"}}/>
                </svg>
            </div>
        );
    }
}

export default ScrollDownArrow;
